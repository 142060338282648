<template>
    <div class="card self-start">
        <h1 class="border-b pb-3 border-gray-400">
            {{ $t('dashboard.weather') }}
        </h1>
        <VueWeatherWidget
            api-key="12a594acfedecc15b1ab290a354f8195"
            title="Stockholm"
            latitude="59.3260664"
            longitude="17.8471233"
            text-color="#f26364"
            bar-color="#f26364"
            :hide-header="true"
            language="sv"
            units="uk"
        />
    </div>
</template>
<script>
// @Filip
import VueWeatherWidget from 'vue-weather-widget';

export default {
    components: {
        VueWeatherWidget,
    },
};
</script>
import 'vue-weather-widget/dist/css/vue-weather-widget.css';
